import axios from 'axios'

const url_backend = `https://hcp-auth-demo.proyectocelvision.net`

export const auth = {
    pedirToken: function() {
        const datos = {
            nombre: 'Smartbits',
            secret: 'cna89189h43ubfc7d19he39',
            apikey: '8jdj387eh728hesadpfj48'
        }
        //console.log(datos)
        //return axios.get(url_backend+`/api/token/?secret=cna89189h43ubfc7d19he39&apikey=8jdj387eh728hesadpfj48`)
        return axios.post(url_backend+`/api/token/`, datos)
    },
    autologin: function(action, autolog) {
        const datos = {
            action: action,
            autolog: autolog
        }
        return axios.post(url_backend+`/api/login/`, datos)
    },
    login: function(action, user, pass) {
        const datos = {
            action: action,
            usuario: btoa(user),
            pass: btoa(pass)
        }
        return axios.post(url_backend+`/api/login/`, datos)
    },
    setPass: function(action, pass, cadena, accion) {
        const datos = {
            action: action,
            pass: btoa(pass),
            data: btoa(cadena),
            accion: btoa(accion)
        }
        return axios.post(url_backend+`/api/login/`, datos)
    },
    comprobarCambioPass: function(action, cadena, accion) {
        const datos = {
            action: action,
            data: btoa(cadena),
            accion: btoa(accion)
        }
        return axios.post(url_backend+`/api/login/`, datos)
    },
    recordarPass: function(action, mail) {
        const datos = {
            action: action,
            mail: btoa(mail)
        }
        return axios.post(url_backend+`/api/login/`, datos)
    },
    multieventos: function(token, action, onDownloadProgress) {
        const datos = {
            tokenUsuario: token,
            action: action
        }
        return axios.post(url_backend+`/api/webapp/`, datos, {onDownloadProgress})
    },
    evento: function (token,action,apikey,secret){
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    like: function (token, action, apikey, secret, like) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
            like: like
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },

    enviaSolicitud: function (token, action, apikey, secret, tipo, telefono, mail, comentario) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
            tipo:tipo,
            telefono:telefono,
            mail:mail,
            comentario:comentario
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    verAlerta: function (token, action, apikey, secret, alerta) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
            alerta:alerta
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    }
}

export const perfil = {
    setNota: function (token, action, apikey, secret, idNota, idPonencia, textoNota) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
            idNota: parseInt(idNota),
            idPonencia: parseInt(idPonencia),
            textoNota: textoNota
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    sendMensaje: function(form) {
        // const datos = {
        //     tokenUsuario:token,
        //     action:action,
        //     apikey: apikey,
        //     secret: secret,
        //     comentario: comentario,
        //     archivos
        // }
        //console.log(archivos.getAll('archivo[]'))
        //return true;
        return axios.post(url_backend+`/api/webapp/`, form, {headers: {
            "Content-Type": "multipart/form-data",
        }})
    },
    getComunicaciones: function(token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },

    getAlertas: function(token, action, apikey, secret){
        return axios.post(`${url_backend}/api/webapp/`, {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
        })
    }
}

export const sede = {
    getSede: function(token, action, provincia) {
        const datos = {
            token: token,
            action: action,
            provincia: provincia
        }
        //return axios.get(url_backend+`/api/data/?token=${token}&action=${action}&provincia=${provincia}`)
        return axios.post(url_backend+`/api/data/`, datos)
    },
}

export const itContigo = {
    guardarReserva: function(token, action, apikey, secret, dia, hora, reserva, motivo) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
            dia: dia,
            hora: hora,
            reserva: parseInt(reserva),
            motivo: motivo
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    enviarContacto: function(token, action, apikey, secret, dia, comentario) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret,
            dia: dia,
            comentario: comentario
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    }
}

export const slider = {
    getSlider: function(token, action) {
        const datos = {
            // token: token,
            // action: action,
            // componente: componente
            tokenUsuario: token,
            action:action,
            apikey:'8jdj387eh728hesadpfj48',
            secret:'cna89189h43ubfc7d19he39'
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    }
}

export const actualizaciones = {
    getAgenda: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getITContigo: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getGaleria: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getVideoBlog: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getFondoDocumental: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getMiViaje: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getMiAlojamiento: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getBanners: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getMisAlertas: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getMisNotas: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getMisComunicaciones: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getInfoLugar: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
    getSoportePresencial: function (token, action, apikey, secret) {
        const datos = {
            tokenUsuario:token,
            action:action,
            apikey: apikey,
            secret: secret
        }
        return axios.post(url_backend+`/api/webapp/`, datos)
    },
}